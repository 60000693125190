.webform {
  display: flex;
  flex-direction: column;
  margin: 80px auto;
  width: 86%;

  .webformForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .caption {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    margin-bottom: 25px;
    margin-top: 10px;
    color: #4e5255;
  }

  .disclaimer {
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
  }

  .webformThankyou {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .webformThankyou svg {
    height: 50px;
    margin-top: -15px;
    margin-bottom: -15px;
    width: 50px;
  }
  .webformThankyou svg * {
    fill: #97d700;
  }
}

.webform form {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.webform form :global(.animated) {
  flex-grow: 1;
  margin-bottom: -30px;
  margin-top: 14px;
}
.webform form :global(.button > div) {
  display: none;
}
.webform form :global(.button .webform-button) {
  font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
}
.webform form :global(.button.submitting) {
  display: block;
  position: relative;
}
.webform form :global(.button.submitting .webform-button) {
  visibility: hidden;
}
.webform form :global(.button.submitting > div) {
  display: block;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

@media screen and (min-width: 1024px) {
  .webform {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .webformText {
      padding-right: 20px;
    }

    .webformForm {
      width: 50%;
    }

    .caption {
      margin-bottom: 0;
    }
  }
}
